<template>
  <div 
    class="main-pop"
     :class="{'show':showPopups}">
      <div
      :id="ids"
      class="box-modal"
      @click="function(){return false}">
        <div class="d-block text-center">
          <div 
            class="close-pop"
            @click="hidePopup()"
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24">
              <g data-name="close">
                <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/>
                <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/>
              </g>
            </svg>
          </div>
          <div v-if="ids==='popupToken' || ids==='popupReward'">
            <b-img :src="dataPopup.image" center rounded class="img-box"></b-img>
            <h3 class="title-popup">{{ dataPopup.title }}</h3>
            <p class="txt-desc">{{ dataPopup.description }}</p>
            <b-button
              class="mt-3 btn-defaultx"
              block
              @click="actionButton()"
              >{{ dataPopup.txtButton }}</b-button
            >
           </div>
           <div v-if="ids==='popupInfo'" style="position: relative;">
              <div class="inform">
                <SyaratAda/>
                <b-button
                  class="mt-3 btn-defaultx"
                  block
                  @click="hidePopup()"
                  >{{ dataPopup.txtButton }}</b-button
                >
              </div>
           </div>
           <div v-if="ids==='popupService'" style="position: relative;">
            <div class="head-service"></div>
              <div class="service">
                <div class="list-store">
                  <div v-for="lists in storeList" :key="lists.id" class="box-list">
                    <div class="head-list">
                      <div class="head-list-desc">
                        <h3>Rp.{{lists.service_charge}}</h3>
                        Untuk +{{lists.token}} token 
                        </div>
                    </div>
                    <div>{{lists.description}}</div>
                    <b-button
                      class="mt-1 btn-defaultx-list"
                      block
                      @click="actionPull(lists.channel,lists.keyword,lists.adn)"
                      >beli</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="backdrop-pop" @click="hidePopup()"/>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import SyaratAda from '@/components/Syarat.vue'
export default {
  components: {
    SyaratAda,
  },
  data: function() {
  return {};
},
  props: {
      dataPopup: Object,
      ids: String,
    },
  computed: {
    ...mapGetters({
      showPopups: 'getPopupShow',
      storeList: 'getStore',
    }),
  },
  methods: {
    ...mapMutations({
      setPopups: 'SET_GAME_POPUP_SHOW',
    }),
      hidePopup() {
        this.setPopups(false)
      },
      actionButton(id) {
        console.log('panggil ini',id)
        //   this.$store.state.coin = 1
          // this.hidePopup(this.ids)
          this.setPopups(false)
          this.$emit('addCoin')
          console.log('clicked', this.$store.state.coin);
      },
      actionPull(channel,keyword,adn){
        this.setPopups(false)
        // window.open("http://117.54.3.23:1481/tsel?aid=1&adn="+adn+"&keyword="+keyword+"","_blank");
        if(channel==='SMS'){
          location.href = 'sms:' + adn + '?body=' + keyword;
        }else if(channel==='UMB'){
          keyword = keyword.slice(0, keyword.length - 1);
          keyword += "%23";
          location.href = 'tel:' + keyword;
        }else if(channel==='WAP'){
          // http://117.54.3.23:1481/tsel?aid=1&adn=90009&keyword=BARUSBAKI7
          window.open("http://117.54.3.23:1481/tsel?aid=1&adn="+adn+"&keyword="+keyword+"","_blank");
        }
      },
      callDataService(){
        console.log(this.storeList)
      },
  }
};
</script>

<style scoped>
/* /deep/ .wd-custom {
    width: 230px !important;
}
/deep/ .title-popup {
    font-size: 1.1rem;
}
/deep/ .txt-desc {
    font-size: .8rem;
} */
.backdrop-pop{
  width:100vw;
  height:100vh;
  background:#0000009e;
  position: relative;
  z-index:1001;
}
.main-pop{
  display:none;
}
.main-pop.show{
  display: block;
}
.box-modal{
  color: #854107 !important;
    background: #ffff91;
    width: 80vw;
    height: calc(93vh - 3rem);
    min-width: 15rem !important;
     max-width: 30rem;
    margin: auto;
    position: absolute;
    z-index: 1002;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 1rem;
    border-radius: 25px;
    box-shadow: #0000004a 1px 7px 0px;
    border-bottom: 7px solid #e37d00;
    text-transform: uppercase;
}

.box-modal h3.title-popup{
    font-size: 5.5vh;
    margin-top: 0.5rem;
    font-weight: 600;
    color: #742203;
}
.img-box{
  height:32vh;
}
.txt-desc{
  font-size:4vh;
}
.close-pop{
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 28px;
    height: 27px;
    fill: #f0d769;
    background: #f28902;
    border-radius: 13px;
    z-index: 25;
}
.btn-defaultx, .btn-defaultx-list{
    position: relative;
    background: #55cb13;
    border-radius: 12px;
    border: #8ddc0d 2px solid;
    margin: auto;
    text-transform: uppercase;
    overflow: hidden;
    max-width: 200px;
    font-weight: 600;
    box-shadow: 0px 5px 1px #2f9113;
}
.title-box{
  font-size: 21px;
    font-weight: 700;
    background: #96ecff;
    border: 5px solid #fff;
    padding: 15px 0px;
    border-radius: 20px;
    color: #95b3db;
    box-shadow: inset 0 0 10px #529cff;
}
.btn-defaultx .light, .btn-defaultx-list .light{
    display: block;
    background-color: #d0f62159;
    position: absolute;
    height: 14px;
    border-radius: 5px;
    left: 0px;
    width: calc(100% - 10px);
    top: 4px;
    margin: 0 5px;
}

.btn-defaultx{
  width: 68% !important;
  position: absolute;
  bottom: -3.2rem;
  left: 0;
  right: 0;
}
.service,.inform{
    margin-top: 1.5rem;
    background: #fff53e;
    border-radius: 25px;
    overflow: scroll;
    box-shadow: inset 0 0 10px #ff5000;
}
.service{
  border-top: 5px solid #ffca43;
  margin-top: 8.5rem;
  height: calc( 100vh - 16.3rem);
}
.inform{
  border-top: 5px solid #ffca43;
  color: #1b7bfc;
  height: calc( 90vh - 9.5rem);
}
.head-service{
  background-image: url("../assets/knopi.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 100%;
  min-width: 11rem !important;
  max-width: 30rem;
  height: 12rem;
  top: -146px;
  z-index: 20;
}

.box-list{
    padding: 0.5rem;
    color: #6d6d6d;
    font-weight: 700;
    border-radius: 20px;
    margin: 0.5rem;
    background-color: #ffffff;
    position: relative;
    padding-bottom: 1rem;
    text-transform: none;
    border-bottom: 5px solid #fff998;
}
.head-list{
   background: #1b7bfc;
   background-image: url("../assets/coins.png");
   background-size: 7rem;
   background-size: cover;
   background-repeat: no-repeat;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding-bottom: 1rem;
    color: #ffffd7;
    position: relative;
    padding-top: 1rem;
    margin: -0.5rem;
    margin-bottom: 1rem;
}
.head-list-desc{
    display: inline-block;
    background: #0000003b;
    padding: 0.5rem 1rem;
    font-weight: 100;
    font-size: 17px;
    border-radius: 9px;
}
.head-list-desc span{
  font-weight: 700;
  color: #ffffff;
  display: block;
  font-size: 25px;
}
#popupInfo{
  width: 90vw;
  max-width: 41rem;
}
@keyframes modalshow {
  0%   { top:400px;}
  100% {top:0px;}
}
.main-pop.show .box-modal{
  animation-name: modalshow;
  animation-duration: 1s;
}
</style>
